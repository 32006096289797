
body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: white }
}

@media (max-width: 600px) {
    .sidebar {
        display: none;
    }
    .content {
        padding: 10px !important;
    }
    #iletisim {
        margin-bottom: 50px !important;
    }
}
@media (min-width: 600px) {
    .mobil-menu {
        display: none !important;
    }
}

.nav-link {
  background: linear-gradient(145deg, #28555e, #6e2bf3);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  border-radius: 20px;
  font-size: 1rem;
  padding: 10px;
  width: 100%;
  transition: all 0.3s ease;
}
.nav-link:hover {
  background-color: rgba(34, 136, 85, 0.5);
  transform: translateX(5px);
}
.nav-link > i {
  font-size: 1.2rem;
}

.mobil-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.mobil-menu button{
    width: 15%;
}

.nav-link2 {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 10px;
    transition: all 0.3s ease;
}
.nav-link2:hover {
    background-color: rgba(34, 136, 85, 0.5);

}
.nav-link2 > i {
    font-size: 2rem;
}
.nav-link2 > span {
    font-size: 0.7rem;
}

/* Ana sayfa stilleri */
.home-image-frame {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.home-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.animate-text {
    opacity: 0;
    animation: fadeInUp 1s ease forwards;
}

@keyframes typing {
    0% { width: 0; }
    50% { width: 52%; }
    100% { width: 0; }
  }

  .typ-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    border-right: 2px solid #666;
    width: 0;
    animation: typing 5s steps(20) infinite;
  }

.typing-text {
    font-size: 1.2rem;
    font-family: 'Courier New', monospace;
}

.typing-text span {
    display: inline-block;
    margin: 0 5px;
    opacity: 0;
    animation: fadeInRight 0.5s ease forwards;
}

.typing-text span:nth-child(1) { animation-delay: 0.5s; }
.typing-text span:nth-child(2) { animation-delay: 0.7s; }
.typing-text span:nth-child(3) { animation-delay: 0.9s; }
.typing-text span:nth-child(4) { animation-delay: 1.1s; }
.typing-text span:nth-child(5) { animation-delay: 1.3s; }
.typing-text span:nth-child(6) { animation-delay: 1.5s; }


@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.description-text {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
    animation: fadeIn 1s ease forwards;
    animation-delay: 1.3s;
    opacity: 0;
}

.description-text .highlight {
    color: #6e2bf3;
    font-weight: 600;
    padding: 0 5px;
}

.description-text .tech-stack {
    color: #28555e;
    font-family: 'Courier New', monospace;
    background: rgba(40, 85, 94, 0.1);
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 500;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Eğitim bölümü stilleri */
.section-title {
  color: #28555e;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-title::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100px;
  height: 3px;
  background: linear-gradient(145deg, #28555e, #6e2bf3);
}

.education-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
}

.education-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}

.education-year {
  font-size: 1.2rem;
  font-weight: 600;
  color: #6e2bf3;
  background: rgba(110, 43, 243, 0.1);
  padding: 10px 20px;
  border-radius: 50px;
  display: inline-block;
}

.university-name {
  font-size: 1.5rem;
  font-weight: 600;
  color: #28555e;
  margin-bottom: 10px;
}

.department-name {
  font-size: 1.2rem;
  color: #6e2bf3;
  margin-bottom: 15px;
}

.education-description {
  color: #666;
  line-height: 1.8;
  margin-bottom: 20px;
}

.course-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.course-tag {
  background: linear-gradient(145deg, #28555e, #6e2bf3);
  color: white;
  padding: 5px 15px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.course-tag:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(110, 43, 243, 0.3);
}

/* İş Tecrübesi stilleri */
.experience-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 30px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
}

.experience-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}

.experience-year {
    font-size: 1.1rem;
    font-weight: 600;
    color: #6e2bf3;
    margin-bottom: 5px;
}

.experience-duration {
    font-size: 0.9rem;
    color: #666;
    font-style: italic;
}

.company-name {
    font-size: 1.3rem;
    font-weight: 600;
    color: #28555e;
    margin-bottom: 10px;
}

.position-name {
    font-size: 1.1rem;
    color: #6e2bf3;
    margin-bottom: 10px;
}

.location {
    color: #666;
    font-size: 0.9rem;
}

.skill-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.skill-tag {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
    color: white;
    padding: 5px 15px;
    border-radius: 20px;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.skill-tag:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(110, 43, 243, 0.3);
}

/* Yetenek kartları stilleri */
.skill-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 25px;
    text-align: center;
    transition: all 0.3s ease;
    height: 100%;
}

.skill-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}

.skill-icon {
    font-size: 2.5rem;
    margin-bottom: 15px;
    color: #6e2bf3;
    height: 80px;
    width: 80px;
    line-height: 80px;
    border-radius: 50%;
    background: linear-gradient(145deg, rgba(40, 85, 94, 0.1), rgba(110, 43, 243, 0.1));
    margin: 0 auto 20px;
}

.skill-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #28555e;
    margin-bottom: 15px;
}

.skill-level {
    margin-top: 15px;
}

.progress {
    height: 20px;
    background-color: rgba(110, 43, 243, 0.1);
    border-radius: 20px;
    overflow: hidden;
}

.progress-bar {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
    transition: width 1s ease;
}

/* Proje kartları stilleri */
.project-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 100%;
}
.project-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}
.project-image {
    position: relative;
    overflow: hidden;
    height: 200px;
}
.project-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}
.project-card:hover .project-image img {
    transform: scale(1.1);
}
.project-content {
    padding: 20px 20px 0px 20px;
}
.project-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #28555e;
    margin-bottom: 10px;
}
.project-description {
    color: #666;
    font-size: 0.95rem;
    margin-bottom: 15px;
    line-height: 1.6;
}
.project-tech {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 20px;
}
.tech-badge {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
    color: white;
    padding: 4px 12px;
    border-radius: 15px;
    font-size: 0.85rem;
    transition: all 0.3s ease;
}
.tech-badge:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(110, 43, 243, 0.3);
}
.project-links {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
}
.project-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
    font-weight: 500;
    transition: all 0.3s ease;
}
.project-link:hover {
    transform: translateX(5px);
}

/* İletişim bölümü stilleri */
.contact-card {
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    padding: 30px;
    height: 100%;
    transition: all 0.3s ease;
}
.contact-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 40px rgba(0,0,0,0.15);
}
.contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.info-item {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    color: #28555e;
}
.info-item i {
    font-size: 1.5rem;
    color: #6e2bf3;
}
.social-link {
    color: #28555e;
    text-decoration: none;
    transition: color 0.3s ease;
}
.social-link:hover {
    color: #6e2bf3;
}
/* Sidebar stilleri */
.sidebar {
    position: sticky;
    top: 0;
    height: 100vh;
    background-color: #120909;
}

.content {
    min-height: 100vh;
}

.footer {
    background: linear-gradient(145deg, #28555e, #6e2bf3);
}
